<template>
    <Content :links="links">

        <template v-slot:content>
            <template v-if="creditNotes && creditNotes.data.length > 0">
                <div class="sm:rounded-md mb-3">
                    <ul role="list" class="">
                        <template v-for="item in creditNotes.data" :key="item.id">
                            <CreditNoteListItem :item="item" />
                        </template>
                    </ul>
                </div>

                <Pagination :meta="creditNotes.meta" :onPageChange="onPageChange" />
            </template>

            <template v-else>
                <p class="py-6 text-center dark:text-gray-300">
                    Diemžēl nekas netika atrasts
                </p>
            </template>
        </template>
    </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import CreditNoteListItem from "../../components/Orders/CreditNotes/CreditNoteListItem";
import Pagination from "@/components/Components/Pagination";
import invoicesLinks from "../../library/appbar/invoicesLinks";

export default {
    name: "CreditNotes",
    components: {
        Pagination,
        CreditNoteListItem
    },
    data: () => ({
        links: invoicesLinks,
    }),
    created() {
        this.$store.dispatch("setDataLoading", true)
    },
    mounted() {
        this.$store.dispatch('removeAllFormsForDisplay')
        this.$store.dispatch('resetSearch')
        const s = Search.getSearchQuery();
        if (s) {
            this.$store.dispatch('changeSearch', s)
            this.searchCreditNotes()
        } else {
            this.$store.dispatch('getCreditNotes', Search.getCurrentPage())
        }
    },
    computed: {
        ...mapGetters({
            creditNotes: 'creditNotes',
            formsForDisplay: 'formsForDisplay',
            search: 'search',
        })
    },
    watch: {
        search: function () {
            this.searchCreditNotes()
        }
    },
    methods: {
        searchCreditNotes: debounce(function () {
            this.$store.dispatch('changeSearch', this.search)
            this.$store.dispatch('getCreditNotes', Search.getCurrentPage())
        }, 500),
        onPageChange(page) {
            this.$store.dispatch('getCreditNotes', page);
            Search.changePage(this.banks.meta.current_page)
        },
        showForm(formName){
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName){
            return this.formsForDisplay.includes(formName)
        },
        hideAddBankForm(){
            this.$store.dispatch('removeAllFormsForDisplay')
        },
    }
}
</script>