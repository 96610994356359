<template>
  <li>
    <item-card :url="`/invoices/credit_notes/${item.id}`" :blank="true" contentClass="grid grid-cols-12">
      <template v-slot:title>
          <Badge :text="item.accepted ? 'Akceptēts' : 'Sagatave'"
                 :className="item.accepted ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800' + 'mr-2 mb-2 md:mb-0'" />
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
          {{ item.customer ? item.customer.name : 'Nav norādīts' }}
        </h3>
      </template>
      <template v-slot:buttons>

      </template>

      <template v-slot:content>

        <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
          <ItemText title="#" :text="item.uuid" />
        </div>
        
        <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
          <ItemText title="Datums" :text="item.date" />
        </div>
        
        <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
          <ItemText title="Klienta filiāle" :text="item.branch ? item.branch.name : 'Nav norādīts'" />
        </div>


          <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-1">
            <ItemText title="Pozīcijas" :text="item.items_count" />
          </div>
          
          <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-1">
            <ItemText title="Kopā EUR" :text="item.total" />
          </div>
          
          <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-1">
            <ItemText title="PVN EUR" :text="item.vat" />
          </div>
          
          <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
            <ItemText title="Summa EUR" :text="item.total_with_vat" />
          </div>

      </template>

      <template v-slot:additional v-if="item.notes">
        <div class="block border-t border-gray-200 dark:border-gray-700 pt-2">
          <div class="py-1" v-if="item.notes">
            <div class="sm:flex flex-wrap">
              <ItemText title="Piezīmes" :text="item.notes" />
            </div>
          </div>
        </div>
      </template>

    </item-card>
  </li>
</template>

<script>
import Badge from "@/components/Components/Badge"
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Colors from "@/services/Helpers/colors";

export default {
  name: "CreditNoteListItem",
  components: {
    ItemCard,
    ItemText,
    Badge,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  methods: {
    viewOrder(orderId){
      this.$router.push({ path: '/orders/' + orderId })
    },
    statusColor(status) {
      let key = status ? status.id : 0
      return Colors.statusColor(key)
    },
    typeColor(id){
      let key = id - 1
      return Colors.typeColor(key)
    }
  }
}
</script>

<style>

</style>